import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/Layout";
import clsx from 'clsx';
import useCommonStyles from '../../assets/jss/commonStyles';
// import Seo from "../../components/Seo";
import RangeSelector from '../../components/RangeSelector';
import DateRangeSelector from '../../components/DateRangeSelector';
import TableListing from '../../components/TableListing';

import { 
  Button, Paper, TextField, Chip, Select, 
  FormControl, InputLabel, MenuItem, IconButton, 
  Slide, Grid, Typography, Input, InputAdornment, Collapse
} from '@material-ui/core';

import * as MUIIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImgsViewer from 'react-images-viewer';

import Helper from '@justnice/helper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import * as systemActions from '../../redux/actions/systemActions';
import * as galleryActions from '../../redux/actions/galleryActions';
import AssetForm from './form';
import Image from '../../components/Image';

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import moment from 'moment';

const hasWindow = typeof window !== 'undefined';
type Order = 'asc' | 'desc';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const filterFormInitialState = () => {
  return {
    pageNum: {value: 0, touched: false, error: false, message: ''},
    pageSize: {value: 0, touched: false, error: false, message: ''},
    keyword: {value: '', touched: false, error: false, message: ''},
    imageFormat: {value: '', touched: false, error: false, message: ''},
    imageMedium: {value: '', touched: false, error: false, message: ''},
    takenOn: {start: '', end: '', touched: false, error: false, message: ''},
    location: {value: '', touched: false, error: false, message: ''},
    cameraModel: {value: '', touched: false, error: false, message: ''},
    cameraFocalLength: {start: '', end: '', touched: false, error: false, message: ''},
    cameraShutterSpeed: {start: '', end: '', touched: false, error: false, message: ''},
    cameraAperture: {start: '', end: '', touched: false, error: false, message: ''},
    cameraIso: {start: '', end: '', touched: false, error: false, message: ''},
    price1: {start: '', end: '', touched: false, error: false, message: ''},
    price2: {start: '', end: '', touched: false, error: false, message: ''},
    price3: {start: '', end: '', touched: false, error: false, message: ''},
    tags: {value: [], touched: false, error: false, message: ''}
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ManagePage : React.FC = (props) => {
  const {t} = useTranslation('gallery-manage');
  const classes = useCommonStyles();
  const [expandFilter, setExpandFilter] = React.useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [filterForm, setFilterForm] = React.useState(filterFormInitialState());
  const [imgViewerParam, setImgViewerParam] = React.useState({curImg: 0, isOpen: false});
  const [selectedImage, setSelectedImage] = React.useState(null);

  const locale = props['ui'].get('lang');
  const tags = Helper.getStateAsJSObj(props['system'], 'tag.data', []);
  const sysList = Helper.getStateAsJSObj(props['system'], 'sysList.data', []);
  const ownerPhoto = Helper.getStateAsJSObj(props['gallery'], 'ownerPhoto.data', null);
  const ownerPhotoLoading = Helper.getStateAsJSObj(props['gallery'], 'ownerPhoto.loading', false);
  const ownerPhotoList = Helper.isNotNullAndUndefined(ownerPhoto) && Helper.arrayHasItem(ownerPhoto.data) ? stableSort(ownerPhoto.data, getComparator(order, orderBy)) : []
  const owner = Helper.getStateAsJSObj(props['session'], 'myProfile.data', null);
  const skeletonDummyRec = {
    _id: '',
    title: '',
    taken_on: '',
    filename: '',
    location: '',
    description: ''
  }
  const tableHeight = hasWindow ? window.innerHeight - 230 : 600;
  const tableRowHeight = 50;
  
  // For google map
  const loaded = React.useRef(false);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAMul7Ly4qDnNcG3s4DfmInkC9GBx840Hg&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    const tagObj = Helper.getStateAsJSObj(props['system'], 'tag', null);
    const sysListObj = Helper.getStateAsJSObj(props['system'], 'sysList', null);
    const galleryObj = Helper.getStateAsJSObj(props['gallery'], 'ownerPhoto', null);
    // If no tag info and tag is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(tagObj) || (!Helper.arrayHasItem(tagObj?.data) && !tagObj?.loading)) {
      props.dispatch(
        systemActions.tagGetList()
      );
    }

    // If no system list info and system list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(sysListObj) || 
       (!Helper.arrayHasItem(sysListObj?.data) && !sysListObj?.loading) ||
       (sysListObj?.locale !== locale && !sysListObj?.loading)) {
      props.dispatch(
        systemActions.sysListGet(locale)
      );
    }

    if(!Helper.isNotNullAndUndefined(galleryObj) || 
       (!Helper.arrayHasItem(galleryObj?.data?.data) && !galleryObj?.loading)) {
      props.dispatch(
        galleryActions.ownerPhotoGetList(filterFormInitialState())
      );
    }

    // For google map
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };

  }, [value, inputValue, fetch]);

  const onFieldChange = (field: string, value : any) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].value = value;
    localFilterForm[field].touched = true;
    setFilterForm(localFilterForm);
  };

  const onRangeStartChange = (field: string, value: string) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].start = value;
    localFilterForm[field].touched = true;
    if(Helper.stringHasValue(localFilterForm[field].end)) {
      validateForm(localFilterForm);
    }
    else {
      setFilterForm(localFilterForm);
    }
  };

  const onRangeEndChange = (field: string, value: string) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].end = value;
    localFilterForm[field].touched = true;
    validateForm(localFilterForm);
  };

  const validateForm = (form=null) => {
    let localForm = Helper.isNotNullAndUndefined(form) ? form : Helper.deepCopy(filterForm);
    let formIsValid = true;

    let fields = Object.keys(localForm);

    fields.forEach(item => {
      localForm[item].error = false;
      localForm[item].message = '';

      if(localForm[item].touched && !['keyword', 'imageFormat', 'imageMedium', 'cameraModel', 'location', 'tags'].includes(item)) {

        if(item === 'takenOn') {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = new Date(localForm[item].start);
            let end = new Date(localForm[item].end);
            if(start.getTime() > end.getTime()) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
        else {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = eval(localForm[item].start);
            let end = eval(localForm[item].end);
            if(start > end) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
      }
    });

    setFilterForm(localForm);
    return formIsValid;
  }

  const clearFilterForm = () => {
    setFilterForm(filterFormInitialState());
  }

  const loadGallery = () => {
    props.dispatch(
      galleryActions.ownerPhotoGetList(filterForm)
    );
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (row) => {
    // console.log('Table row clicked', row);
    setSelectedImage(row);
  }

  //#region Populate focalLengthList
  const focalLengthList = [];
  for(let idx=0; idx <= 200; idx++) {
    focalLengthList.push(
      <MenuItem key={`focal_length_${idx}`} value={(idx === 0 ? 1 : idx * 10).toString()}>
        {`${idx === 0 ? 1 : idx * 10}mm`}
      </MenuItem>
    );
  }
  //#endregion

  //#region Populate shutterSpeedList
  const shutterSpeedList = [];
  for(let idx=8000; idx >= 125; idx = idx / 2) {
    shutterSpeedList.push(
      <MenuItem key={`shutter_speed_${idx}`} value={`1/${idx}`}>
        {`1/${idx}`}
      </MenuItem>
    );
  }
  for(let idx=60; idx >= 15; idx = idx / 2) {
    shutterSpeedList.push(
      <MenuItem key={`shutter_speed_1_${idx}`} value={`1/${idx}`}>
        {`1/${idx}`}
      </MenuItem>
    );
  }
  for(let idx=8; idx >= 2; idx = idx / 2) {
    shutterSpeedList.push(
      <MenuItem key={`shutter_speed_1_${idx}`} value={`1/${idx}`}>
        {`1/${idx}`}
      </MenuItem>
    );
  }
  for(let idx=1; idx <= 8; idx = idx * 2) {
    shutterSpeedList.push(
      <MenuItem key={`shutter_speed_${idx}`} value={`${idx}`}>
        {`${idx}s`}
      </MenuItem>
    );
  }
  for(let idx=15; idx <= 120; idx = idx * 2) {
    shutterSpeedList.push(
      <MenuItem key={`shutter_speed_${idx}`} value={`${idx}`}>
        {`${idx}s`}
      </MenuItem>
    );
  }
  //#endregion
  
  //#region Populate apertureList
  const apertureValueList = [0.7, 0.8, 0.9, 1, 1.1, 1.2, 1.4, 1.6, 1.8, 2, 2.2, 2.4, 2.8, 3.2, 3.5, 4, 4.5, 5, 5.6, 6.3, 7.1, 8, 9, 10, 11, 13, 14, 16, 18, 20, 22, 25, 29, 32, 36, 42, 45, 50, 57, 64];
  const apertureList = [];
  apertureValueList.forEach(item => {
    apertureList.push(
      <MenuItem key={`aperture_${item.toString()}`} value={item.toString()}>
        {`f ${item.toString()}`}
      </MenuItem>
    );
  });
  //#endregion

  //#region Populate isoList
  const isoValueList = [1, 1.2, 1.6, 2, 2.5, 3, 4, 5, 6, 8, 10, 12, 16, 20, 25, 32, 40, 50, 64, 80, 100, 125, 160, 200, 250, 320, 400, 500, 640, 800, 1000, 1250, 1600, 2000, 2500, 3200, 6400, 8000, 10000, 12500, 16000, 20000, 25000, 32000, 40000, 50000, 64000, 80000, 100000, 125000, 160000, 200000, 250000, 320000, 400000, 500000, 640000, 800000, 1000000, 1250000, 1600000, 2000000, 2500000, 3200000, 4000000];
  const isoList = [];
  isoValueList.forEach(item => {
    isoList.push(
      <MenuItem key={`iso_${item.toString()}`} value={item.toString()}>
        {`${item.toLocaleString()}`}
      </MenuItem>
    );
  });
  //#endregion

  //#region Populate priceList
  const priceList = [];
  for(let idx=0; idx < 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x10`} value={(idx === 0 ? 1 : idx * 10).toString()}>
        {`$${(idx === 0 ? 1 : idx * 10).toLocaleString()}`}
      </MenuItem>
    );
  }
  for(let idx=1; idx < 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x100`} value={(idx * 100).toString()}>
        {`$${(idx * 100).toLocaleString()}`}
      </MenuItem>
    );
  }
  for(let idx=1; idx <= 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x1000`} value={(idx * 1000).toString()}>
        {`$${(idx * 1000).toLocaleString()}`}
      </MenuItem>
    );
  }
  //#endregion

  return (
    <Layout title={t('pageTitle')}>
      {/* <Seo title={t('title')} /> */}
      
      <Paper variant="outlined" className={classes.form} style={{marginTop: 0, marginBottom: 0}}>
        <div className={classes.formRow} style={{marginBottom: 0}}>
          <FormControl style={{display: 'flex', flex: 1}}>
            <InputLabel htmlFor="input-with-icon-adornment">{t('filter.header')}</InputLabel>
            <Input
              id="input-with-icon-adornment"
              value={filterForm.keyword.value}
              onChange={event => onFieldChange('keyword', event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <MUIIcons.Search />
                </InputAdornment>
              }
            />
          </FormControl>
          <IconButton onClick={() => setExpandFilter(!expandFilter)}>
            {
              !expandFilter ?
              <MUIIcons.ExpandMore />
              :
              <MUIIcons.ExpandLess />
            }
          </IconButton>
        </div>
        <Collapse in={expandFilter} style={{marginTop: !expandFilter ? 0 : 20}}>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <TextField id="cameraSetting.model" label={t('filter.field.label.cameraModel')} 
                variant="outlined" 
                value={filterForm.cameraModel.value}
                onChange={event => onFieldChange('cameraModel', event.target.value)}
                // onBlur={(event) => validateFormField('cameraModel', event.target.value)}
                error={filterForm.cameraModel.error}
                helperText={filterForm.cameraModel.message}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <Autocomplete
                id="google-map-location"
                // style={{ width: 300 }}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                // getOptionSelected={(option) => option.description === uploadForm.location}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                onChange={(event, newValue) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  onFieldChange('location', newValue ? newValue.description : '')
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('filter.field.label.imageLocation')} variant="outlined" fullWidth />
                )}
                renderOption={(option) => {
                  const matches = option.structured_formatting.main_text_matched_substrings;
                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                  );

                  return (
                    <Grid container alignItems="center">
                      <Grid item>
                        <MUIIcons.LocationOn  />
                      </Grid>
                      <Grid item xs>
                        {parts.map((part, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                            {part.text}
                          </span>
                        ))}

                        <Typography variant="body2" color="textSecondary">
                          {option.structured_formatting.secondary_text}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                }}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <FormControl variant="outlined">
                <InputLabel id="imageFormatLabel">{t('filter.field.label.imageFormat')}</InputLabel>
                <Select
                  labelId="imageFormatLabel"
                  id="imageFormat"
                  value={filterForm.imageFormat.value}
                  onChange={event => onFieldChange('imageFormat', event.target.value as string)}
                  // onBlur={(event) => validateFormField('imageFormat', event.target.value)}
                  label={t('filter.field.label.imageFormat')}
                >
                  {
                    sysList
                    .filter(item => item.category === 'IMAGE_FORMAT')
                    .map(item => (
                      <MenuItem value={item.code} key={`IMAGE_FORMAT_${item.code}`}>
                        {item.text}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <FormControl variant="outlined">
                <InputLabel id="imageMediumLabel">{t('filter.field.label.imageMedium')}</InputLabel>
                <Select
                  labelId="imageMediumLabel"
                  id="imageMedium"
                  value={filterForm.imageMedium.value}
                  onChange={event => onFieldChange('imageMedium', event.target.value as string)}
                  // onBlur={(event) => validateFormField('imageMedium', event.target.value)}
                  label={t('filter.field.label.imageMedium')}
                >
                  {
                    sysList
                    .filter(item => item.category === 'IMAGE_MEDIUM')
                    .map(item => (
                      <MenuItem value={item.code} key={`IMAGE_MEDIUM_${item.code}`}>
                        {item.text}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.camera.focalLength'} 
                labelId={'filter.field.label.camera.focalLength'}
                label={t('filter.field.label.cameraFocalLength')}
                value={[filterForm.cameraFocalLength.start, filterForm.cameraFocalLength.end]}
                startRangeList={focalLengthList}
                endRangeList={focalLengthList}
                error={filterForm.cameraFocalLength.error}
                errorMsg={filterForm.cameraFocalLength.message}
                onStartChanged={(value) => onRangeStartChange('cameraFocalLength', value)}
                onEndChanged={(value) => onRangeEndChange('cameraFocalLength', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.camera.shutterSpeed'} 
                labelId={'filter.field.label.camera.shutterSpeed'}
                label={t('filter.field.label.cameraShutterSpeed')}
                value={[filterForm.cameraShutterSpeed.start, filterForm.cameraShutterSpeed.end]}
                startRangeList={shutterSpeedList}
                endRangeList={shutterSpeedList}
                error={filterForm.cameraShutterSpeed.error}
                errorMsg={filterForm.cameraShutterSpeed.message}
                onStartChanged={(value) => onRangeStartChange('cameraShutterSpeed', value)}
                onEndChanged={(value) => onRangeEndChange('cameraShutterSpeed', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.camera.aperture'} 
                labelId={'filter.field.label.camera.aperture'}
                label={t('filter.field.label.cameraAperture')}
                value={[filterForm.cameraAperture.start, filterForm.cameraAperture.end]}
                startRangeList={apertureList}
                endRangeList={apertureList}
                error={filterForm.cameraAperture.error}
                errorMsg={filterForm.cameraAperture.message}
                onStartChanged={(value) => onRangeStartChange('cameraAperture', value)}
                onEndChanged={(value) => onRangeEndChange('cameraAperture', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.camera.iso'} 
                labelId={'filter.field.label.camera.iso'}
                label={t('filter.field.label.cameraIso')}
                value={[filterForm.cameraIso.start, filterForm.cameraIso.end]}
                startRangeList={isoList}
                endRangeList={isoList}
                error={filterForm.cameraIso.error}
                errorMsg={filterForm.cameraIso.message}
                onStartChanged={(value) => onRangeStartChange('cameraIso', value)}
                onEndChanged={(value) => onRangeEndChange('cameraIso', value)}
              />
            </div>            
          </div>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.imagePrice1'} 
                labelId={'filter.field.label.imagePrice1'}
                label={t('filter.field.label.imagePrice1')}
                value={[filterForm.price1.start, filterForm.price1.end]}
                startRangeList={priceList}
                endRangeList={priceList}
                error={filterForm.price1.error}
                errorMsg={filterForm.price1.message}
                onStartChanged={(value) => onRangeStartChange('price1', value)}
                onEndChanged={(value) => onRangeEndChange('price1', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.imagePrice2'} 
                labelId={'filter.field.label.imagePrice2'}
                label={t('filter.field.label.imagePrice2')}
                value={[filterForm.price2.start, filterForm.price2.end]}
                startRangeList={priceList}
                endRangeList={priceList}
                error={filterForm.price2.error}
                errorMsg={filterForm.price2.message}
                onStartChanged={(value) => onRangeStartChange('price2', value)}
                onEndChanged={(value) => onRangeEndChange('price2', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.imagePrice3'} 
                labelId={'filter.field.label.imagePrice3'}
                label={t('filter.field.label.imagePrice3')}
                value={[filterForm.price3.start, filterForm.price3.end]}
                startRangeList={priceList}
                endRangeList={priceList}
                error={filterForm.price3.error}
                errorMsg={filterForm.price3.message}
                onStartChanged={(value) => onRangeStartChange('price3', value)}
                onEndChanged={(value) => onRangeEndChange('price3', value)}
              />
            </div>           
          </div>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 400}} >
              <DateRangeSelector 
                id={'filter.field.imageTakenOn'} 
                labelId={'filter.field.label.imageTakenOn'}
                label={t('filter.field.label.imageTakenOn')}
                value={[filterForm.takenOn.start, filterForm.takenOn.end]}
                type={'date'}
                error={filterForm.takenOn.error}
                errorMsg={filterForm.takenOn.message}
                onStartChanged={(value) => onRangeStartChange('takenOn', value)}
                onEndChanged={(value) => onRangeEndChange('takenOn', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 3, minWidth: 200, paddingTop: 10}} >
              <Autocomplete
                className={classes.formField}
                multiple
                id="photoTag"
                options={tags.map((option) => option.value)}
                freeSolo
                disableCloseOnSelect
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={t('filter.field.label.imageTag')} placeholder={t('filter.field.placeholder.imageTag')} />
                )}
                value={filterForm.tags.value}
                onChange={(event, value, reason) => onFieldChange('tags', value)}
              />
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
            <Button variant="contained" onClick={() => {clearFilterForm()}}>
              {t('button.clear')}
            </Button>
            <div style={{width: 30}} />
            <Button variant="contained" color="primary" onClick={() => {
              if(validateForm()) {
                loadGallery()
              }
            }}>
              {t('button.apply')}
            </Button>
          </div>
        </Collapse>
      </Paper>

      <Paper variant="outlined" 
        className={classes.form}
        style={{ height: tableHeight, width: '100%' }}
      >
        <TableListing
          tableHeight={tableHeight}
          rowHeight={tableRowHeight}
          rowCount={!ownerPhotoLoading ? ownerPhotoList.length : (Math.floor(tableHeight / tableRowHeight))}
          rowGetter={({ index }) => !ownerPhotoLoading ? ownerPhotoList[index] : skeletonDummyRec}
          columns={[
            {
              width: 100,
              label: t('listing.field.image'),
              dataKey: '_id',
              alignment: 'center',
              renderer: (data) => {
                let image = null;

                if(Helper.isNotNullAndUndefined(data) && Helper.stringHasValue(data)) {
                  // image = <img
                  //   src={`${process.env.GATSBY_API_URL}/assets/thumbnail/${data}?token=${owner ? owner.access_token : ''}`}
                  //   alt=""
                  //   height={40}
                  // />
                  image = <Image
                    src={`${process.env.GATSBY_API_URL}/assets/thumbnail/${data}?token=${owner ? owner.access_token : ''}`}
                    height={40}
                  />
                }

                return image;
              },
              onClick: (event, data) => {
                //event.preventDefault();
                event.stopPropagation();
                // console.log('Row Image Clicked!', event, data);

                let idx = ownerPhotoList.findIndex(item => item._id === data);
                if(idx >= 0) {
                  setImgViewerParam({curImg: idx, isOpen: true});
                }
              }
            },
            {
              width: window.innerWidth * 0.15,
              label: t('listing.field.title'),
              dataKey: 'title',
            },
            {
              width: window.innerWidth * 0.1,
              label: t('listing.field.taken_on'),
              dataKey: 'taken_on',
              renderer: (data) => {
                let returnValue ='';
                if(Helper.isNotNullAndUndefined(data) && Helper.stringHasValue(data)) {
                  let takenOn = moment(data);
                  returnValue = takenOn.format('DD MMM yyyy HH:mm:ss');
                }
                return returnValue;
              }
            },
            {
              width: window.innerWidth * 0.1,
              label: t('listing.field.filename'),
              dataKey: 'filename'
            },
            {
              width: window.innerWidth * 0.15,
              label: t('listing.field.location'),
              dataKey: 'location',
              renderer: (data) => {
                return data ? data['name'] : '';
              }
            },
            {
              width: window.innerWidth * 0.4,
              label: t('listing.field.description'),
              dataKey: 'description'
            }
          ]}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onRowClick={({ index }) => onRowClick(ownerPhotoList[index])}
          showSkeleton={ownerPhotoLoading}
        />
      </Paper>

      {
        Helper.arrayHasItem(ownerPhotoList) &&
        <ImgsViewer
          imgs={ownerPhotoList.map(item => ({
            src: `${process.env.GATSBY_API_URL}/assets/view/${item._id}?token=${owner ? owner.access_token : ''}`,
            thumbnail: `${process.env.GATSBY_API_URL}/assets/thumbnail/${item._id}?token=${owner ? owner.access_token : ''}`,
            caption: `${item.title} - ${t('image.viewer.taken_on')} ${moment(item.taken_on).format('DD MMM yyyy HH:mm:ss')}`
          }))}
          backdropCloseable={true}
          showThumbnails={true}
          currImg={imgViewerParam.curImg}
          isOpen={imgViewerParam.isOpen}
          onClickPrev={() => setImgViewerParam({curImg: imgViewerParam.curImg <= 0 ? 0 : imgViewerParam.curImg - 1, isOpen: true})}
          onClickNext={() => setImgViewerParam({curImg: imgViewerParam.curImg >= ownerPhotoList.length - 1 ? ownerPhotoList.length - 1 : imgViewerParam.curImg + 1, isOpen: true})}
          onClickThumbnail={(index) => setImgViewerParam({curImg: index, isOpen: true})}
          onClose={() => setImgViewerParam({curImg: 0, isOpen: false})}
        />  
      }

      <Slide direction="left" in={Helper.isNotNullAndUndefined(selectedImage) } mountOnEnter unmountOnExit>
        <Paper elevation={4} style={{
          zIndex: 1,
          position: 'absolute',
          left: 0,
          top: 70,
          width: window.innerWidth,
          height: window.innerHeight - 70
        }}>
          <AssetForm classes={classes} tags={tags} sysList={sysList} 
            asset={selectedImage}
            owner={owner}
            showCancel={true}
            showDiscard={false}
            showDelete={true}
            onCancel={() => {setSelectedImage(null)}}
            // onDiscard={onDiscard}
            onUpload={(error, data) => {
              if(!error) {
                props.dispatch(
                  galleryActions.ownerPhotoUpdateItem(data)
                );

                setTimeout(() => {
                  setSelectedImage(null);
                }, 1000);
              } 
            }}
            onDelete={(error, data) => {
              if(!error) {
                props.dispatch(
                  galleryActions.ownerPhotoDeleteItem(data)
                );

                setTimeout(() => {
                  setSelectedImage(null);
                }, 1000);
              } 
            }}
          />
          
        </Paper>
      </Slide>
    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    page: state['page'],
    gallery: state['gallery']
  };
})(ManagePage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
